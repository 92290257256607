<template>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-12 item">
      <!-- Begin services item -->
      <router-link to="#" class="services-item item-style">
        <div class="services-item-ico">
          <i class="material-icons material-icons-outlined md-48">flight</i>
        </div>
        <h4 class="services-item-title">Flights</h4>
        <div class="servises-item-desc">
          <p>
            <span>Our flight cache receives regular updates throughout the day from our suppliers. Using our live API it can be interrogated 24x7x365 and in real time builds the cheapest split return flights.</span>
            <span>Data can be collected either via live API or by using our flat file service allowing regular updates from our FTP server.</span>
          </p>
          <p>We have an extensive range of suppliers which vary from time to time but our current active list includes:</p>
          <table border="0">
            <tbody>
              <tr>
                <td>Aegean</td>
                <td>AerLingus</td>
                <td>Air Arabia</td>
                <td>Airmalta</td>
                <td>Aurigny</td>
              </tr>
              <tr>
                <td>British Airways</td>
                <td>Balkan Holidays</td>
                <td>easyJet</td>
                <td>Flybe</td>
                <td>Fly Thomas Cook</td>
              </tr>
              <tr>
                <td>Goldmedal</td>
                <td>
                  <a rel="noopener" href="http://www.jet2.com" target="_blank" class="color1">Jet2</a>
                </td>
                <td>Norwegian</td>
                <td>Olympic Holidays</td>
                <td>RedSea Holidays</td>
              </tr>
              <tr>
                <td>Ryanair</td>
                <td>Thomson</td>
                <td>ThomsonFly</td>
                <td>Vueling</td>
                <td>Wizzair</td>
              </tr>
            </tbody>
          </table>
          <p>Using our cheapest flight tool, you can quickly find the route and date you are searching for and spot the cheapest pricing.</p>
          <p>
            <img
              style="width: 100%;"
              src="/media/1037/cheapflights.png?width=1106&amp;height=197"
              alt
              rel="1760"
              data-id="1760"
            />
          </p>
        </div>
      </router-link>
      <!-- End services item -->
    </div>

    <div class="col-lg-4 col-md-6 col-12 item">
      <!-- Begin services item -->
      <router-link to="#" class="services-item item-style">
        <div class="services-item-ico">
          <i class="material-icons material-icons-outlined md-48">hotel</i>
        </div>
        <h4 class="services-item-title">Accommodation</h4>
        <div class="servises-item-desc">
          <p>Working with all the major UK bed banks, Thomas Cook, Youtravel, Medhotels, Magic Rooms. Hotelbeds, Triton, Pierre &amp; Vacances and Olympic we have an extensive range of accommodation cached, either via overnight pricing updates or by polling XML feeds.</p>
          <p>Once this data is available in our high speed cache, it can packaged with our flight cache and output for distribution or used directly for accommodation only searches.</p>
          <p>
            <span>Using our cheapest accommodation tool, you can quickly find the property, star rating and date you are searching for and see the cheapest pricing.</span>
          </p>
          <p>
            <span>
              <img
                style="width: 100%;"
                src="/media/1092/cheapesthotels.png?width=1106&amp;height=197"
                alt
                rel="1859"
                data-id="1859"
              />
            </span>
          </p>
          <p></p>
        </div>
      </router-link>
      <!-- End services item -->
    </div>

    <div class="col-lg-4 col-md-6 col-12 item">
      <!-- Begin services item -->
      <router-link to="#" class="services-item item-style">
        <div class="services-item-ico">
          <i class="material-icons material-icons-outlined md-48">web</i>
        </div>
        <h4 class="services-item-title">Packages</h4>
        <div class="servises-item-desc">
          <p>With over 60 million unique holiday packages in our availability cache, covering all ranges of room types and board basis departing from all UK departure points we have an extensive range of large and small UK tour operators.</p>
          <p>Combine this availability with our premium content service, all details of the holiday offer can be presented using our live API, offers can be de-duplicated and the cheapest offer by supplier can be presented.</p>
          <p>Using commercial overlay tools margins, mark-ups and discounts can be applied to relevant offers prior to distribution.</p>
        </div>
      </router-link>
      <!-- End services item -->
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style>
</style>